import React, { Component } from "react";
import { debounce, classList } from "../../Utils";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import ScrollTo from "../common/ScrollTo";

class TopBar3 extends Component {
  state = {
    isTop: true,
    isClosed: true
  };
  handleScrollRef;

  componentDidMount() {
    if (window) {
      this.handleScrollRef = this.handleScroll();
      window.addEventListener("scroll", this.handleScrollRef);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("scroll", this.handleScrollRef);
    }
  }

  openCalendly = () => {
    window.Calendly.initPopupWidget({url: 'https://calendly.com/anthea-demo'});
  }

  handleScroll() {
    return debounce(() => {
      if (window) {
        let isTop = window.scrollY < 100;
        if (isTop !== this.state.isTop) {
          this.setState({ isTop });
        }
      }
    }, 20);
  }

  close = () => {
    this.setState({ isClosed: true });
  };
  render() {
    let toggleIcon = this.state.isClosed ? "menu" : "close";
    return (
      <section
        className={classList({
          header: true,
          "header-fixed": !this.state.isTop,
          closed: this.state.isClosed
        })}
      >
        <div className="container header-container">
        <ScrollTo to="intro" onScroll={this.close}>
          <div className="brand">
            {this.state.isClosed ? <img src="/TrumarksPoweredByLogoFinal.png" alt="" /> : <img src="/TrumarksFaviconFinal.ico" alt="" />}
          </div>
          </ScrollTo>
          {!this.state.isClosed && (
            <Fab
                    variant="extended"
                    key="cta"
                    size="medium"
                    color="primary"
                    aria-label="Schedule demo"
                    onClick={this.openCalendly}
                    style={{margin: '16px 0px 48px 0px'}}
                  >
                    <Icon className="mr-16">calendar_today</Icon>
                    Schedule demo
                  </Fab>
          )}
          <ul className="navigation">
            <li>
              <ScrollTo to="how_it_works" onScroll={this.close}>
                How it works
              </ScrollTo>
            </li>
            <li>
              <ScrollTo to="features" onScroll={this.close}>
                Features
              </ScrollTo>
            </li>
            <li>
              <ScrollTo to="how_we_are_different" onScroll={this.close}>
                We are trusted
              </ScrollTo>
            </li>
          </ul>

          {this.state.isClosed && [
            <div className="m-auto" key="breaker"/>,
            <Fab
                    variant="extended"
                    key="cta"
                    size="medium"
                    color="primary"
                    aria-label="Schedule demo"
                    onClick={this.openCalendly}
                  >
                    <Icon className="mr-16">calendar_today</Icon>
                    Schedule demo
                  </Fab>
          ]}

          <IconButton
            className="header__toggle"
            onClick={() => {
              this.setState({ isClosed: !this.state.isClosed });
            }}
          >
            <Icon>{toggleIcon}</Icon>
          </IconButton>
        </div>
      </section>
    );
  }
}

export default TopBar3;
