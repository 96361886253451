import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import ScrollTo from "../common/ScrollTo";
import { ReactComponent as SecureServer } from "../common/SecureServer.svg";

class Intro1 extends Component {
  state = { };
  openCalendly = () => {
    window.Calendly.initPopupWidget({url: 'https://calendly.com/anthea-demo'});
  }
  render() {
    return (
      <section className="section section-intro1" id="intro"
      style={{background: 'linear-gradient(25deg,#0d47a1 15%,#64b5f6 97%)'}}>
        <div className="container">
          <div className="section-intro1__logo" style={{marginTop: '-64px', marginBottom: '48px'}}>
            <div className="brand">
              <img src="/TrumarksPoweredByLogoFinalGrey.png" alt="" style={{height: '48px'}} />
            </div>
          </div>
          <Grid container spacing={24} justify="center">
            <Grid item md={6}>
              <h1 className="section-intro1__title">
                Counterfeit Protection for Cannabis Brands
              </h1>
              <div className="section-intro1__subtitle">
                Simple, yet powerful counterfeit protection designed to build trust with your customers.
              </div>

              <div className="section-intro1__list">
                <div className="section-intro1__list__item">
                  <Icon color="secondary">check</Icon> Customer connections.
                </div>
                <div className="section-intro1__list__item">
                  <Icon color="secondary">check</Icon> Counterfeit protection.
                </div>
                <div className="section-intro1__list__item">
                  <Icon color="secondary">check</Icon> Flexible print integrations.
                </div>
              </div>

              <div>
                <Fab
                  variant="extended"
                  size="large"
                  color="secondary"
                  aria-label="Schedule demo"
                  className="btn-action m-8"
                  onClick={this.openCalendly}
                >
                  <Icon className="mr-16">calendar_today</Icon>
                  Schedule demo
                </Fab>
                <ScrollTo to="how_it_works">
                <Button
                  variant="outlined"
                  size="large"
                  aria-label="Learn more"
                  className="section-intro1__btn-secondary"
                >
                  Learn more
                  <Icon className="ml-8">keyboard_arrow_down</Icon>
                </Button>
                </ScrollTo>
              </div>
            </Grid>
            <Grid item md={6} >
              <div className="section-intro1__product">
              <SecureServer
                style={{
                  height: "calc(36vh)",
                  width: "420px",
                  margin: "24px 0px"
                }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  }
}

export default Intro1;
