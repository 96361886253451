import React, { Component } from "react";
import { Tabs, Tab, Grid,  Switch } from "@material-ui/core";

class HowItWorks extends Component {
  state = {
    customerValue: 0,
    brandValue: 0,
  };

  brandSteps = [
    {
      imageUrl: "./BatchScreenshot.png",
      title: "Initiate a batch",
      text:
        "First, you input your product information on the Trumarks cloud-based interface. Initiating a batch generates a unique, secure fingerprint for every individual label. Trumark codes do not repeat and cannot be guessed. Copies are detected almost immediately."
    },
    {
      imageUrl: "./DesignWithCoffee.png",
      title: "Design your label",
      text:
        "You work with your preferred print design software and using a Trumarks flex-print file, you integrate the Trumarks onto your packaging. Already have artwork? Trumarks integrates seamlessly with your existing print design process."
    },
    {
      imageUrl: "ExampleLabel.png",
      title: "Print labels",
      text:
        "From here, you print and apply your labels just the way you do now. It fits your packaging. From single label codes printed on inkjets, to direct to graphics packaging printers, flex-print gives you what you need. The only difference is, with Trumarks each item is secure with the power to communicate with and protect your customers."
    },
    {
      imageUrl: "Relax.png",
      title: "Sit back and relax",
      text: "Rest Easy. Your product and brand are protected. Trumarks quietly keeps watch on scans and can identify unusual activity. You receive full reports on scan locations, times, and other activity that can be used to trace any counterfeit activity or protect your brand in court."
    },
  ];

  customerSteps = [
    {
      imageUrl: "ScanCode.png",
      title: "Scan code",
      text:
        "Your customer scans the Trumarks using a smartphone. Trumarks is an easy to read code that can be scanned by any modern iPhone or Android device. Your customers do not need install special apps or give personal data."
    },
    {
      imageUrl: "Scan.png",
      title: "Authenticate the product",
      text:
        "Next, your customer goes through an age and product verification process. Once a code is scanned by your customer, they may be asked to confirm the picture of your product, the date of purchase, or even the dispensary where it was bought. Knockoffs can’t keep up with Trumarks' multifactor, variable, verification."
    },
    {
      imageUrl: "SocialShare.png",
      title: "Engage with the brand",
      text:
        "Your customer has the opportunity to engage with the product brand through an engagement platform setup by Trumarks. Examples of options for engagement platforms for brands are links to the brand's website or specific landing page, embedded videos or social sharing prompts. All interactions are captured with Trumarks' anonymized customer analytics platform - in compliance with cannabis marketing controls."
    }
  ];

  handleChangeBrand = (event, value) => {
    this.setState({ brandValue: value });
  };

  handleChangeCustomer = (event, value) => {
    this.setState({ customerValue: value });
  };


  render() {
    const { customerValue, brandValue } = this.state;
    const customerStep = this.customerSteps[customerValue];
    const brandStep = this.brandSteps[brandValue]

    return (
      <div>
      <div className="section section-service5" id="how_it_works">
        <div className="container">
        <div className="section__header">
          <h2>How it Works</h2>
          <p>See how Trumarks works for both you and for your customer</p>
        </div>
        <div class="wistia_responsive_padding" style={{padding:"56.25% 0 0 0", position: "relative"}}>
          <div class="wistia_responsive_wrapper" style={{height:"100%", left:0, position: "absolute", top:0, width: "100%"}}>
            <iframe src="https://fast.wistia.net/embed/iframe/iuzei7cfjj?videoFoam=true" title="Trumarks - powered by Anthea Video" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe>
          </div>
        </div>
        </div>
      </div>
      <div className="section section-service5 light-gray" id="how_it_works">
        <div className="container">
        <div className="section__header">
          <h2>How it Works for you</h2>
        </div>
        <Grid
          container
          >
        <Grid item lg={7} md={5} sm={12} xs={12}>
            <Tabs
              value={brandValue}
              onChange={this.handleChangeBrand}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              centered
              scrollButtons="auto"
            >
              <Tab label="Step 1" />
              <Tab label="Step 2" />
              <Tab label="Step 3" />
              <Tab label="Step 4" />
            </Tabs>
          </Grid>
          <Grid item lg={5} md={7} sm={12} xs={12} />
          </Grid>
          <Grid
            container
            spacing={32}
            className="mt-16 fade-in"
            alignItems="center"
          >
            <Grid item lg={7} md={5} sm={12} xs={12}>
              <div className="service5__image-holder">
                <img src={brandStep.imageUrl} alt="building" />
              </div>
            </Grid>
            <Grid item lg={5} md={7} sm={12} xs={12}>
              <div className="service5__details">
                <h2>{brandStep.title}</h2>
                <p>{brandStep.text}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="section section-service5" id="how_it_works">
        <div className="container">
        <div className="section__header">
          <h2>How it Works for your customers</h2>
        </div>

        <Grid
          container
          >
        <Grid item lg={7} md={5} sm={12} xs={12}>
            {/* tab view */}
            <Tabs
              value={customerValue}
              onChange={this.handleChangeCustomer}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              centered
              scrollButtons="auto"
            >
              <Tab label="Step 1" />
              <Tab label="Step 2" />
              <Tab label="Step 3" />
            </Tabs>
          </Grid>
          <Grid item lg={5} md={7} sm={12} xs={12} />
          </Grid>
          <Grid
            container
            spacing={32}
            className="mt-16 fade-in"
            alignItems="center"
          >
            <Grid item lg={7} md={5} sm={12} xs={12}>
              <div className="service5__image-holder">
                <img src={customerStep.imageUrl} alt="building" />
              </div>
            </Grid>
            <Grid item lg={5} md={7} sm={12} xs={12}>
              <div className="service5__details">
                <h2>{customerStep.title}</h2>
                <p>{customerStep.text}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      </div>
    );
  }
}

export default HowItWorks;
