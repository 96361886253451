import React, { Component } from "react";
import { Grid, Card, Icon } from "@material-ui/core";

class BenefitsFrontEnd extends Component {
  state = {};
  cardList = [
    {
      icon: "fingerprint",
      text: "Unique fingerprint"
    },
    {
      icon: "crop_free",
      text: "Intuitive Scan"
    },
    {
      icon: "favorite_border",
      text: "Satisfied consumers"
    },
    {
      icon: "insert_chart_outlined",
      text: "Customer analytics"
    }
  ];

  render() {
    return (
      <div className="section section-service3">
        <div className="container">
        <div className="section__header">
          <h2>Simple and powerful for your customers</h2>
        </div>
          <Grid container spacing={40} alignItems="center">
            <Grid item lg={6} md={6} sm={12}>
              <Grid container spacing={32}>
                {this.cardList.map((card, index) => (
                  <Grid item lg={6} md={6} sm={6} xs={12} key={index}>
                    <Card className="service__card p-24 card text-center h-100">
                      <div className="rounded-icon primary mb-16">
                        <Icon>{card.icon}</Icon>
                      </div>
                      <p className="m-0 text-uppercase">{card.text} </p>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <h2 className="">
                Stand out with 1 to 1 customer connections
              </h2>
              <p>
              You should build relationships with your customers.
              <br />
              <br />
              Trumarks opens a window between you and individual customers. Set your products apart by giving your customers a way to trust you and love you.
              You can provide any information your customers want to see, from dosing to lab tests. Link your customers to your social media or other platforms without problems.
              Trumarks is an easy to read code that can be scanned by any iPhone or Android device. Your customers do not need install special apps or give personal data.
              <br/>
              <br/>
              Schedule a demo today and discover how putting the Trumarks on your packaging will drive customer loyalty.
              <br />
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default BenefitsFrontEnd;
