import React, { Component } from "react";
import { Grid, Card } from "@material-ui/core";

class WeAreTrusted extends Component {
  cardList = [
    {
      text:
        "Certified Food Scientist & Industry Thought Leader; Francis is a pragmatic, academically certified, business specialist with over 30-years’ experience in research devices, healthcare, and food science. Dr. Boero is currently providing pro-bono services to the Institute of Food Technologists working group on Legalized Cannabis and Hemp edibles.",
      user: {
        imageUrl: "Francis.jpeg",
        name: "Francis Boero",
      }
    },
    {
      text:
        "Experienced digital product creator and marketer; Dan has extensive experience bringing user-friendly cloud-based applications to market and scaling them. Prior to his work in the world of digital products, Dan provided actuarial consulting services to some of the world's largest financial institutions and holding companies.",
      user: {
        imageUrl: "dan22.jpeg",
        name: "Dan Dingman",
      }
    },
    {
      text:
        "Traceability, Labeling and GS1 Compliance Specialist; Bob is an experienced traceability engineer and business coach with a gift for taking complex info and making it clear. Bob has extensive experience with smart tags, and other overt, covert and forensic technologies. Bob is one of the preeminent sources of information in the fields of authentication, traceability and smart tag technology.",
      user: {
        imageUrl: "Bob.jpeg",
        name: "Bob Driggers",
      }
    },
    // {
    //   text:
    //     "Supply Chain Security and Brand Protection Specialist; Brad brings over 35 years of professional experience to the world of supply chain security and brand protection. With nearly 30 years of pharmaceutical industry experience, and sixteen of those years with cargo security programs. Brad is also certified as an ISO 28000 Supply Chain Security Auditor.",
    //   user: {
    //     imageUrl: "Brad.jpeg",
    //     name: "Brad Elrod",
    //   }
    // },
    {
      text:
        "Mike is a recognized industry leader in brand protection packaging substrates and the integration of forensic authentication technology and verification.  He currently serves as the CEO Potsdam Specialty Paper Inc. and Founder of Nanomarker Technology LLC; He has been active with security paper, documents, and packaging for 30 years. ",
      user: {
        imageUrl: "Mike.jpeg",
        name: "Mike Huth",
      }
    }
  ];

  state = {};

  render() {
    return (
      <div className="section section-testimonial3 light-gray" id="we_are_trusted">
        <div className="container">
          <div className="section__header">
            <h2>Who we are</h2>
          </div>
          <Grid container spacing={40} justify="center" alignItems="center">
            {this.cardList.map((card, index) => (
              <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
                <Card className="py-32 px-24 card">
                <Grid container spacing={16} alignItems="center">
                  <Grid item>
                    <img src={card.user.imageUrl} alt="face" />
                  </Grid>
                  <Grid item>
                  <h4 style={{marginBottom: '0px', color: "#616161"}}>{card.user.name}</h4>
                  </Grid>
                </Grid>
                  <p>{card.text}</p>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}

export default WeAreTrusted;
