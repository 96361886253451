import React from "react";
import "./styles/sass/app.scss";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Theme from "./Theme";
import Landing from "./components/Landing";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";

function App() {
  return (
    <MuiThemeProvider theme={Theme}>
      <Router basename="/">
        <Switch>
          <Route path="/" component={Landing} exact />
          <Route path="/privacy-policy" component={Privacy} exact />
          <Route path="/terms-of-use" component={Terms} exact />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
