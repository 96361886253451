import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";

class HowWeAreDifferent extends Component {
  itemList = [
    {
      icon: "store_mall_directory",
      text: "Online stores"
    },
    {
      icon: "account_balance",
      text: "Finance services"
    },
    {
      icon: "home",
      text: "Industry"
    },
    {
      icon: "fastfood",
      text: "Churches"
    },
    {
      icon: "category",
      text: "Logistics"
    }
  ];
  state = {};
  render() {
    return (
      <div className="section section-service7" id="how_we_are_different">
        <div className="container">
        <div className="section__header">
          <h2>We are trusted</h2>
          </div>
          <Grid container alignItems="center" spacing={40}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="service7__card__description">

                <p>
                  We are marketing and intellectual property experts with over 100 years combined experience in health and wellness, pharmaceuticals, and authentication.
                  We have seen how easy it is for knock offs to buy ‘custom’ holograms offshore to deceive customers.
                  <br/>
                  <br/>
                  We’re not a bank vault.Trumarks opens a window between you and individual customers. You can provide any
                  information your customers want to see, from dosing to lab tests. Link your customers to your social media or other platforms without problems.
                  <br/>
                  <br/>
                  We have a different business model. We don’t ask for coupon fees, we don’t charge for marketing, and we don’t resell your information.
                </p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} style={{textAlign: 'center'}} >
              <img src="trust.png" alt="trust" />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default HowWeAreDifferent;
