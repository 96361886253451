import React, { Component } from "react";
import { Grid, Icon } from "@material-ui/core";


class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="section-footer1 light-dark" id="footer">
        <div className="container">
          <Grid container>
            <Grid item lg={6} md={6} sm={12}>
              <div className="footer1__about">
                <h4>About Us</h4>
                <p>
                  Trumarks is powered by Anthea. Anthea is a full-service cannabis brand protection firm, offering a wide-range of technology and service solutions customized for the cannabis industry.
                </p>
              </div>
            </Grid>
            <Grid item lg={3} md={3} sm={12}>
              <div className="footer1__contact">
                <h4>Contact</h4>
                <div className="px-16 my-32">
                  <Icon className="footer1__contact__icon">mail</Icon>
                  <div className="pl-16">
                    <h5 className="m-0 p-0">Email</h5>
                    <p className="m-0 p-0">info@antheapro.com</p>
                  </div>
                </div>
                <div className="px-16 mt-32">
                  <Icon className="footer1__contact__icon">location_on</Icon>
                  <div className="pl-16">
                    <h5 className="m-0 p-0">Address</h5>
                    <p className="m-0 p-0">Philadelphia, PA</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={3} md={3} sm={12}>
              <div className="footer1__disclaimer">
                <h4>Disclaimer</h4>
                <p>
                  Trumarks is property of Anthea LLC
                </p>
                <a href="/privacy-policy" className="footer1__disclaimer__link" target="_blank">Privacy Policy</a>
                <br/>
                <a href="/terms-of-use" className="footer1__disclaimer__link" target="_blank">Terms of Use</a>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Footer;
