import React, { Component } from "react";
import { Grid, Card, Icon} from "@material-ui/core";
// flexible icon?, print icon, could based?, scalable, architecture?, proprietary random number generator, secure, intelligent, simple setup
// icons: cloud, print,  security, code

class BenefitsBackEnd extends Component {
  state = {};
  cardList = [
    {
      icon: "cloud",
      title: "Cloud based platform",
      text:
        "Trumarks' facility operator software is cloud-based and simple to use. Input your product information once, add custom messages for each item, and generating unique codes for label printing is 1-click away."
    },
    {
      icon: "print",
      title: "Flexible print integration",
      text:
        "Trumarks' flex-print fits your packaging - from single label codes printed on inkjets, to direct to graphics packaging printers. This allows you to integrate world-class brand protection into your existing print processes."
    },
    {
      icon: "verified_user",
      title: "Secure code generation",
      text:
        "Trumarks proprietary code generation algorithm provides robust protection for your product. Each label is a unique fingerprint that does not repeat. Trumarks works while you sleep to detect unusual activity."
    },
    {
      icon: "code",
      title: "Scalable and customizable",
      text: "From startup level production to major production, Trumarks scales with you. Codes, customer responses, and analysis are scalable to your needs, from thousands to millions of Trumarks per month."
    }
  ];
  render() {
    return (
      <div className="section section-service4 light-gray" id="features">
        <div className="container">
          <div className="section__header">
            <h2>Simple and powerful for you</h2>
          </div>
          <div className="text-center" >
            <Grid container spacing={40}>
              {this.cardList.map((card, index) => (
                <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
                  <Card className="service4__card card">
                    <Icon className="service4__card__icon">{card.icon}</Icon>
                    <div className="service4__card__description">
                      <h3>{card.title}</h3>
                      <p className="m-0">{card.text}</p>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default BenefitsBackEnd;
