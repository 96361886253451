import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";


class CallToAction extends Component {
  state = {};
  openCalendly = () => {
    window.Calendly.initPopupWidget({url: 'https://calendly.com/anthea-demo'});
  }
  render() {
    return (
      <div className="section section-cta1" id="cta1"
      style={{background: 'linear-gradient(25deg,#0d47a1 15%,#64b5f6 97%)'}}>
        <div className="container">
          <Grid
            container
            spacing={24}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <h2>Simple for you, simple for your customers</h2>
              <p>With the power to communicate and protect</p>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
            <Fab
              variant="extended"
              size="large"
              className="btn-action m-8"
              color="secondary"
              aria-label="Schedule demo"
              onClick={this.openCalendly}
            >
              <Icon className="mr-16">calendar_today</Icon>
              Schedule your demo today
            </Fab>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default CallToAction;
