import React, { Component } from "react";
import { scrollTo } from "../Utils";
import BenefitsBackEnd from "./sections/BenefitsBackEnd";
import BenefitsFrontEnd from "./sections/BenefitsFrontEnd";
import HowItWorks from "./sections/HowItWorks";
import HowWeAreDifferent from "./sections/HowWeAreDifferent";
import WeAreTrusted from "./sections/WeAreTrusted";
import Intro from "./sections/Intro";
import TopBar from "./sections/TopBar";
import CallToAction from "./sections/CallToAction";
import CallToAction2 from "./sections/CallToAction2";
import Footer from "./sections/Footer";
// import Contact from "./sections/Contact";

class Landing extends Component {
  state = {};
  componentWillUnmount() {
    scrollTo('root');
  }

  render() {
    return (
      <div className="landing">
        <TopBar />
        <Intro />
        <HowItWorks />
        <BenefitsBackEnd />
        <BenefitsFrontEnd />
        <CallToAction />
        <HowWeAreDifferent />
        <WeAreTrusted />

        <CallToAction2 />
        <Footer />
      </div>
    );
  }
}

export default Landing;
