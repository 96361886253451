import React, { Component } from "react";
import termlyFunc from '../termly'

class Terms extends Component {
  state = {};
  componentDidMount() {
    termlyFunc(document, 'script', 'termly-jssdk')
  }

  render() {
    return (
      <div  style={{padding: '16px'}}>
       <div style={{textAlign: 'center', margin: 'auto',}}><a href="/" ><img src="/TrumarksPoweredByLogoFinal.png" style={{cursory: 'pointer', height: '40px'}} alt=""/></a></div>
       <div name="termly-embed" data-id="a0c6f2a9-51ef-4423-964f-816674f00496" data-type="iframe"></div>
      </div>
    );
  }
}

export default Terms;
